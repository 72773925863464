$black-text: 			#141414;
$gray-text: 			#8D8F9B;
$blue-text: 			#005FF9;
$white-text:            #FFFFFF;
$red-text:              #E20523;


$white-color:			#FFFFFF;
$black-color:			#000000;
$transparent-dark:      rgba(0, 0, 0, 0.5);
//colores corporativos
$blue-color:            #005FF9;
$superdark-blue-color:  #0047ba;
$dark-blue-color:       #0255DC;
$light-blue-color:      #d9e7ff;
$light-grey-color:      #F0F0F0;
$grey-color:            #FAFAFA;
$dark-grey-color:       rgba(255, 255, 255, 0.2);
$lightgreen-color:      #4EF000;



//FONT
$font-family-barlow:    'Barlow';
$font-bold:             700;
$font-semi-bold:        600;
$font-normal:           400;
$font-500:              500;

$font-size-8:           8px;
$font-size-9:           9px;
$font-size-10:          10px;
$font-size-11: 	        11px;
$font-size-12: 	        12px;
$font-size-13: 	        13px;
$font-size-14: 	        14px;
$font-size-15: 	        15px;
$font-size-16: 	        16px;
$font-size-17:          17px;
$font-size-18: 	        18px;
$font-size-19: 	        19px;
$font-size-20: 	        20px;
$font-size-22: 	        22px;
$font-size-21: 	        21px;
$font-size-23: 	        23px;
$font-size-24: 	        24px;
$font-size-25: 	        25px;
$font-size-26: 	        26px;
$font-size-27: 	        27px;
$font-size-28: 	        28px;
$font-size-29: 	        29px;
$font-size-30: 	        30px;
$font-size-32: 	        32px;
$font-size-34: 	        34px;
$font-size-36: 	        36px;
$font-size-40: 	        40px;
$font-size-41: 	        41px;
$font-size-42: 	        42px;
$font-size-43: 	        43px;
$font-size-44: 	        44px;
$font-size-45: 	        45px;
$font-size-52: 	        52px;
$font-size-66: 	        66px;

//ICONS
$fonts-awesome-free:    'Font Awesome\ 5 Free';
$arrow-right:           "\f061";
$flecha-derecha:        url(../images/flecha-derecha.svg);
$flecha-derecha-blue:   "";
$arrow-left:            "\f060";
$to-down:               "\f078";
$to-left:               "\f053";
$to-right:              "\f054";
$to-top:                "\f077";
$check:                 "\f00c";
$ic-lupa:               url(../images/ic-lupa.svg);
$ic-lupa-blue:          url(../images/ic-lupa-blue.svg);
$ic-user:               url(../images/ic-user.svg);
$ic-user-blue:          url(../images/ic-user-blue.svg);

$close:                 url(../images/close-lightbox.svg);
//$close:                 "\f00d";
$buscar:                "\f002";
$twitter:               "\f009";
$linkedin:              "\f0e1";
$ext-link:              url(../images/ext-link.svg);
$ext-link-white:        url(../images/ext-link-white.svg);
$ic-graficas:           url(../images/icon-atyt.png);
$ic-graficas-blue:      url(../images/icon-atyt-blue.svg);
//$ext-link:              "\f35d";
$ic-telefono:           url(../images/ic-telefono.svg);
$ic-email:               url(../images/ic-email.svg);
$ic-point-ubicacion:    url(../images/ico_point_ubicacion.svg);
$ic-point-ubicacion-white: url(../images/icono-location.svg);
$ic-calendar:           url(../images/ico-calendar.svg);
$ic-documento:          url(../images/ic-documento.svg);
$ic-calendar-blue:      url(../images/ico-calendar-blue.svg);
$ic-documento-blue:     url(../images/ic-documento-blue.svg);

*{
    box-sizing: inherit !important;
}