.content{
    width: inherit;
}
.view-informativo-basico{
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    margin: auto!important;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    @media(min-width: 1024px){
        max-width: 864px;
    }
    @media(min-width: 1280px){
        max-width: 1080px;
    }
    @media(min-width: 1680px){
        max-width: 1420px;
    }
    &.txt-right{
        .informativo-basico-wrapper{

            @media(min-width: 1024px){
                order: 2;
                padding: 0px 0px 0px 60px;
            }
        }
        .field-imagen-single{
            .field-cta-arrow{
                @media(min-width: 1024px){
                    left: calc(100% - 65px);
                }
            }
        }
    }
    .informativo-basico-wrapper{
        width: 100%;
        display: flex;
        //padding: 60px 0px;
        @media(min-width: 1024px){
            width: 50%;
        }
        @media(max-width: 1024px){
        }
        .inner-content{
            height: max-content;
            margin: auto;
            display: grid;
            .logo-dominion{
                img{
                    padding-top: 30px;
                    @media(min-width: 768px){
                        order: 2;
                        padding-top: 0px;
                        padding-bottom: 100px;
                        width: 100px;
                    }
                    @media(max-width: 768px){
                        max-width: 65px;
                    }
                }
            }
            .field-titulo-seccion{
                margin-top: 30px;
                color: #141414;
                font-size: 22px;
                font-family: Barlow;
                font-weight: 400;
                line-height: 32px;
                color: $black-text;
                @media(min-width: 768px){
                    margin-top: 0px;
                    margin-bottom: 30px;
                    padding-right: 100px;
                    font-size: 50px;
                    letter-spacing: 0;
                    line-height: 64px;    
                }
            }
            .field-texto-descripcion{
                padding-right: 0px;
                margin-top: 30px;
                margin-bottom: 30px;
                color: #141414;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 28px;
                font-family: Barlow;
                font-weight: 400;
                @media(min-width: 1024px){
                    padding-right: 100px;
                }
            }
            .field-cta-link{
                width: 100%;
                @media(min-width: 1024px){
                    width: max-content;
                    transition: transform 0.5s;
                }
                &:hover{
                    transform: translate(30px,0);
                    transition: transform 0.5s;
                }
                a{
                    font-family: Barlow;
                    font-size: 15px;
                    line-height: 26px;
                    color: #141414;
                    font-weight: 500;
                    @media(min-width: 1024px){
                        font-size: px;
                    }
                    &:hover{
                        text-decoration: none;
                    }
                    img{
                        width: 50px;
                    }
                }
            }
        }
    }
    .field-imagen-single{
        width: 100%;
        position: relative;
        padding-bottom: 20px;
        height: 100%;
        @media(min-width: 768px){
            display: flex;
        }
        &:hover{
            .field-cta-arrow{

            }
            >a{
                img{
                    transform: scale3d(1.1,1.1,1);
                }
            }
        }
        @media(min-width: 1024px){
            width: 50%;
            height: inherit;
            margin-bottom: -81px;
        }
        >a{
            overflow: hidden;
            display: flex;
            height: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center center;
                transition: transform 0.5s;
            }
        }

    }
}