#sp-bottom-colums{
    z-index: 2;
    overflow: hidden;
    @media(min-width: 768px){
        overflow: inherit;
        overflow-y: visible;
    }
    > .row{
        background-color: $white-color;
        @media(min-width: 768px){
            width: 100%;
        }
        #sp-bottom1{
            width: 100%;
            background-color: #0255DC;
            order: 2;
            padding: 0px 35px;
            @media(min-width: 768px){
                width: 35%;
                order: 1;
                background-color: $dark-blue-color;
                margin-bottom: -80px;
            }
            .footer-bottom-column-1{
                .sp-module{
                    .sp-module-content{
                        .custom{
                            box-sizing: inherit;
                            .dominion-footer-menu-wrapper{
                                @media(min-width: 768px){
                                    display: grid;
                                    padding-top: 30px;
                                    width: max-content;
                                }
                                .logo-dominion{
                                    img{
                                        padding: 20px 0px;
                                        max-width: 65px;
                                        @media(min-width: 768px){
                                            order: 2;
                                            padding-bottom: 35px;
                                            max-width: 100px;
                                            margin-left: 70px;
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
}


//enlace footer
#sp-footer{
    background-color: $blue-color;
    //padding: 0px 20px;
    @media(min-width: 768px){
        border: none;
    }
    >.row{
        padding: 0px;
        margin: 0px;
        #sp-footer1{
            margin: 0;
            padding: 0;
            @media(min-width: 768px){
                //padding-left: 25%;
            }
            .sp-column{
                padding: 20px 0px;
                @media (min-width: 768px) {
                    display: flex;
                }
                >.row{
                    justify-content: flex-end;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    .inner-content{
                        //padding: 0px 80px;
                        @media(max-width: 768px){
                        }
                        @media(min-width: 769px){
                            display: flex;
                            padding: 0px 50px;
                        }
                        @media(min-width: 992px){
                        }
                    }
                }
                .legal-links{
                    list-style: none;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 0px;
                    margin-bottom: 10px;
                    @media(min-width: 768px){
                        width: inherit;
                        margin-bottom: 0px;
                        text-align: right;    
                        margin-left: auto;
                        margin-right: 100px;
                    }
                    @media(min-width: 768px){
                        margin-right: 0px;
                    }
                    .item-footer-link-copyeright{
                        opacity: 0.7;
                        color: #FFFFFF;
                        font-size: 10px;
                        letter-spacing: 0;
                        line-height: 18px;
                        font-family: Barlow;
                        font-weight: 500;
                    }
                    li{
                        width: fit-content;
                        display: inline-block;
                        margin: 10px 0px;
                        margin-right: 15px;
                        @media(min-width: 992px){
                            margin-right: 25px;
                        }
                        @media(min-width: 1200px){
                            margin-right: 50px;
                        }
                    }
                }
            }
        }
    }
}